<template>
  <div style="width: 350px; height: 350px; direction: ltr">
    <div class="puzzleTitle">{{ lang }}{{ translation.tryThePuzzle }}</div>
    <VueSlidePuzzle
      :ratio="3"
      mode="far"
      :src="puzzleImageSrc.base64"
      @card-drop="onUserMoved"
      @card-touchend="onUserMoved"
      ref="puzzle"
    />
    <div class="puzzleTitle">{{ translation.numberOfTry }} {{ count }}</div>
    <div class="skipPuzzle" @click="skipPuzzle">
      {{ translation.skipPuzzle }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        fill="currentColor"
        class="bi bi-arrow-right-short"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
        />
      </svg>
    </div>
    <div v-if="completed" class="over">
      <div class="myModal">
        <img src="../../static/img/like.gif" alt="" />
      </div>
    </div>
    <div v-if="nextTime" class="over">
      <div class="nextTimeMessage">
        {{ translation.nextTime }}
      </div>
    </div>
  </div>
</template>


<script>
import { VueSlidePuzzle } from "vue-slide-puzzle";
import "vue-slide-puzzle/dist/VueSlidePuzzle.css";

export default {
  components: {
    VueSlidePuzzle,
  },
  data() {
    return {
      pass: false,
      nextTime: false,
      count: 0,
      completed: false,
      correctAnswerSound: require("../../static/sounds/correctAnswer.mp3"),
    };
  },
  mounted() {},
  computed: {
    puzzleImageSrc() {
      return {
        base64: Object.values(this.$parent.points)[
          this.$parent.pointsArrayOrder[this.$parent.pointNum] - 1
        ].puzzleImageBase64,
      };
    },
    lang() {
      // console.log("this.$store.getters.lang", this.$store.getters.lang);
      return this.translate(this.$store.getters.lang);
    },
  },
  methods: {
    translate(lang) {
      switch (lang) {
        case "fr":
          this.translation = {
            nextTime: "Presque ! Tu feras mieux la prochaine fois !",
            tryThePuzzle: "Vous avez 30 mouvements pour finir le puzzle",
            skipPuzzle: "Passer",
            numberOfTry: "Nombre de mouvements :",
          };
          break;
        case "en":
          this.translation = {
            nextTime: "Almost! You'll do better next time!",
            tryThePuzzle: "You have 30 moves to complete the puzzle",
            skipPuzzle: "Skip",
            numberOfTry: "Number of moves:",
          };
          break;
        case "de":
          this.translation = {
            nextTime: "Fast geschafft! Nächstes Mal klappt es besser!",
            tryThePuzzle: "Sie haben 30 Züge, um das Puzzle zu beenden",
            skipPuzzle: "Weiter",
            numberOfTry: "Anzahl der Züge:",
          };
          break;
        default:
          // Default to English if no language matches
          this.translation = {
            nextTime: "Almost! You'll do better next time!",
            tryThePuzzle: "You have 30 moves to complete the puzzle",
            skipPuzzle: "Skip",
            numberOfTry: "Number of moves:",
          };
          console.log(`Language ${lang} not supported, defaulting to English.`);
      }
    },

    onUserMoved() {
      this.count++;
      const div = document.querySelector(".card");
      setTimeout(() => {
        if (div.classList.contains("over") || this.count > 29) {
          if (this.count > 29) {
            this.nextTime = true;
          } else {
            this.completed = true;
            this.$parent.showStarUp(30 - this.count);
            this.$parent.errorNumber =
              this.$parent.errorNumber + 30 - this.count;
            localStorage.setItem("errorNumber", this.$parent.errorNumber);
          }
          var audio = new Audio(this.correctAnswerSound);
          audio.play();
          setTimeout(() => {
            this.$parent.nextPointNumber();
            this.$parent.showPointInformation = false;
            localStorage.setItem(
              "showPointInformation",
              this.$parent.showPointInformation
            );
            this.$parent.showPickedItem = false;
            localStorage.setItem("showPickedItem", this.$parent.showPickedItem);
            this.$parent.showMainScreen = true;
            localStorage.setItem("showMainScreen", this.$parent.showMainScreen);
            //  console.log("this.pointNum", this.$parent.pointNum);
            this.count = 0;
            this.nextTime = false;
            this.pass = false;
          }, 2000);
        }
      }, 200);
    },
    skipPuzzle() {
      this.completed = true;
      var audio = new Audio(this.correctAnswerSound);
      audio.play();
      setTimeout(() => {
        this.$parent.nextPointNumber();
        this.$parent.showPointInformation = false;
        localStorage.setItem(
          "showPointInformation",
          this.$parent.showPointInformation
        );
        this.$parent.showPickedItem = false;
        localStorage.setItem("showPickedItem", this.$parent.showPickedItem);
        this.$parent.showMainScreen = true;
        localStorage.setItem("showMainScreen", this.$parent.showMainScreen);
        // console.log("this.pointNum", this.$parent.pointNum);
        this.count = 0;
        this.nextTime = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.slide-puzzle {
  overflow: hidden !important;
}
.puzzleTitle {
  font-size: 20px;
  max-width: 360px;
  padding: 10px;
  text-align: center;
  color: white;
  /* backdrop-filter: blur(10px); */
}
.skipPuzzle {
  color: red;
  font-size: 15px;
  position: fixed;
  bottom: 88px;
  right: 10px;
}
.over {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  font-size: 18px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 3;
}
.myModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  background: white;
  border-radius: 100%;
  height: 220px;
  width: 220px;
  border: #0070b0 solid 3px;
}
.nextTimeMessage {
  text-align: center;
  background: white;
  color: #0070b0;
  font-size: 25px;
  border-radius: 10px;
  /* border: 5px yellow solid; */
  font-family: "VarelaRound";
  height: auto;
  padding: 20px;
}
.myModal img {
  height: 200px;
  border-radius: 100%;
}
</style>
